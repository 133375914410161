import { library } from '@fortawesome/fontawesome-svg-core'
import { faTh, faChevronDown, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faGithub,
} from '@fortawesome/free-brands-svg-icons'


library.add(faTh, faChevronDown, faLongArrowAltRight);
library.add(faBookmark);
library.add(faFacebook, faTwitter, faInstagram, faLinkedin, faGithub);
